import React from 'react'
const screenshot_noChat = './images/screenshots/website_no_chat.jpg'
const screenshot_chatClosed = './images/screenshots/website_with_chat_closed.jpg'
const screenshot_chatGreeting = './images/screenshots/website_whith_chat_greeting.jpg'
const screenshot_chatConvo = './images/screenshots/website_with_chat_convo.jpg'
const screenshot_dashboard = './images/screenshots/leChat_dashboard_screenshot.png'
const arrow_down = './images/icons/redArrowDown.png'
const arrow_left = './images/icons/redArrowLeft.png'
const arrow_right = './images/icons/redArrowRight.png'

export default function WhatIsLeChat() {
	return (
		<section className='whatIsLeChat'>
			<h2 className='bottomSpacer'>What is a website chat?</h2>
			<div className='grid2'>

				<div>
					<article>
						<h3 className='textRightAligned noMarginTop'>Connect with your website visitors instantly</h3>
						<p className='textRightAligned'>Traditional contact forms are slow. Le Chat offers <span className='highlighter'>a faster, more personal way for visitors</span> to reach you directly.</p>
					</article>
				</div>
				<div>
					<img className='screenshot' src={screenshot_noChat} />
					<img src={arrow_down} className='mobile icon arrows' />
				</div>


				<div>
					<img className='screenshot' src={screenshot_chatClosed} /><img src={arrow_down} className='mobile icon arrows' /></div>
				<div>
					<article>
						<h3 className='textLeftAligned noMarginTop'>Simple, always visible chat widget</h3>
						<p className='textLeftAligned'>Le Chat adds a small chat icon in the bottom-right corner of your site, non-intrusive but <span className='highlighter'>always accessible</span>.</p>
					</article>
				</div>





				<div>
					<article>
						<h3 className='textRightAligned noMarginTop'>Easy to start a conversation</h3>
						<p className='textRightAligned'>Visitors can click the chat icon to <span className='highlighter'>start a chat or be prompted automatically</span> with custom messages you set up in Le Chat.</p>
					</article>
				</div>
				<div>
					<img className='screenshot' src={screenshot_chatGreeting} /><img src={arrow_down} className='mobile icon arrows' />
				</div>



				<div>
					<img className='screenshot' src={screenshot_chatConvo} /><img src={arrow_down} className='mobile icon arrows' />
				</div>
				<div>
					<article>
						<h3 className='textLeftAligned noMarginTop'>Stay notified anytime, anywhere</h3>
						<p className='textLeftAligned'>Get instant alerts via email or our mobile app so you can respond quickly and <span className='highlighter'>turn visitors into happy customers</span>.</p>
					</article>
				</div>



				<div>
					<article>
						<h3 className='textRightAligned  noMarginTop'>Manage chats your way</h3>
						<p className='textRightAligned'>Reply from the <span className='highlighter'>mobile app</span> (iOS & Android) or access full features through the <span className='highlighter'>web Le Chat dashboard</span>.</p>
					</article>
				</div>
				<div>
					<img className='screenshot' src={screenshot_dashboard} />
				</div>


			</div>
		</section>
	)
}